<template>
  <div class="d-flex flex-column border px-3 rounded">
    <div class="pt-2 pb-3 d-flex flex-column">
      <h6 class="mb-0">
        {{ approver.approver_action }}
      </h6>
      <small>
        {{ !['Pending', 'Requested'].includes(approver.status) || Number(approver.stage) === Number(paymentRequest.stage) ? `Time Lapsed: ${getTimeLapsedFromCurrentApproverToPrevious()}` : '&nbsp;' }}
      </small>
    </div>
    <div class="d-flex flex-column border-bottom mt-1">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex flex-column w-100">
          <b>
            {{ approver.approver_name ? approver.approver_name : '&nbsp;' }}
          </b>
          <i class="d-flex flex-column">
            <div class="d-flex flex-row justify-content-between">
              <small>
                {{ approver.approver_label }}
              </small>
              <small>
                <b
                  v-if="(!['Pending'].includes(String(approver.status))) || (!['Disapproved', 'Canceled'].includes(String(paymentRequest.status)))"
                  :class="`text-${colorBy(approver.status)} align-self-end`"
                >
                  {{ approver.status }}
                </b>
              </small>
            </div>
            <small
              v-if="approver.last_seen && approver.status_at === null"
              class="text-right"
            >
              {{ `Seen: ${dateTimeLongFormatter(approver.last_seen)}` }}
            </small>
            <small
              v-else
              class="text-right"
            >
              {{ approver.status_at ? dateTimeLongFormatter(approver.status_at) : '&nbsp;' }}
            </small>
          </i>
        </div>
      </div>
    </div>
    <div class="pt-2">
      <b>Remarks:</b>
      <p>
        {{ approver.remarks ? approver.remarks : '&nbsp;' }}
      </p>
    </div>
  </div>
</template>
<script>
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'
import dateDifference from 'moment'

export default {
  name: 'Signatory',
  mixins: [formatter, misc],
  props: {
    approver: {
      type: Object,
      default: () => ({
        stage: 0,
        approver_label: null,
        approver_action: null,
        approver_name: null,
        last_seen: null,
        status: null,
        status_at: null,
        remarks: null
      })
    },
    paymentRequest: {
      type: Object,
      default: () => ({
        stage: 0,
        status: null,
        created_at: null,
        approvals: []
      })
    }
  },

  methods: {
    getTimeLapsedFromCurrentApproverToPrevious () {
      if (Number(this.approver.stage) === Number(this.paymentRequest.stage) && ['Pending', 'Requested'].includes(this.approver.status)) {
        const lastApprover = this.paymentRequest.approvals.find(
          approval => Number(approval.stage) < Number(this.approver.stage) && (
            Number(approval.stage) === (Number(this.approver.stage) - 1)
          )
        )
        if (lastApprover) {
          return dateDifference(lastApprover.status_at).fromNow()
        }
        return dateDifference(this.paymentRequest.created_at).fromNow()
      } else {
        const lastApprover = this.paymentRequest.approvals.find(
          approval => Number(approval.stage) < Number(this.approver.stage) && (
            Number(approval.stage) === (Number(this.approver.stage) - 1)
          )
        )
        if (lastApprover) {
          return dateDifference(this.approver.status_at).from(
            dateDifference(lastApprover.status_at),
            true
          )
        }
        return dateDifference(this.paymentRequest.created_at).from(
          dateDifference(this.approver.status_at),
          true
        )
      }
    }
  }
}
</script>
